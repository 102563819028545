/* eslint-disable react/display-name */
import React from 'react';
import { Container } from 'reactstrap';
import Layout from '../../../shared/Layout';
import SEO from '../../../seo';
import SectionContainer from '../../sections/SectionContainer';
import RowManager from '../shared/RowManager';
import contentParser from '../../../utils/contentParser';
import SearchWidget from '../../../shared/SearchWidget/SearchWidget';
import useReduxHandler from '../../../../hooks/useReduxHandler';
// import { getBgColor } from '../shared/ApiManager/util';

const BlogTemplate = ({ pageContext }) => {
	const {
		pathname: path,
		slug,
		title,
		description,
		keywords,
		headerMenus,
		footerTopMenus,
		footerBottomMenus,
		posts,
		sections,
		styles,
		searchOptions,
		pagination,
	} = pageContext;

	const { isLoggedIn } = useReduxHandler();
	const isLogged = isLoggedIn();

	const sectionContent = sections.filter(
		(section) =>
			section.type !== 'hero' &&
			section.type !== 'advisors' &&
			section.type !== 'members'
	);

	return (
		<>
			<Layout
				isPrivate={false}
				isLogged={isLogged}
				type="blogPost"
				header="dark"
				menu={headerMenus}
				classNameMain="bg-blog"
				footerTopMenu={footerTopMenus}
				footerBottomMenu={footerBottomMenus}
				path={path}
				slug={slug}
			>
				<SEO
					title={title}
					description={description}
					keywords={keywords}
					pathname={path}
					cssStyle={styles && contentParser.getOnlyContent(styles)}
				/>
				<Container>
					<SearchWidget searchOptions={searchOptions} searchLabel="The Edge" />
				</Container>
				{sectionContent &&
					sectionContent.length > 0 &&
					sectionContent.map((section) => {
						return (
							<SectionContainer
								key={`${section.id}`}
								id={
									(section.sidebarTitle &&
										section.sidebarTitle.replace(/\s+/g, '_')) ||
									section.name
								}
								className={`public-section ${slug}-section ${section.name} ${
									section.className || ''
								}`}
								slug={slug}
							>
								<Container
								// style={{ backgroundColor: getBgColor(section.bgColor) }}
								>
									<RowManager
										key={section.id}
										row={section}
										slug={slug}
										posts={posts}
										pagination={pagination}
									/>
								</Container>
							</SectionContainer>
						);
					})}
			</Layout>
		</>
	);
};

export default BlogTemplate;
